require('../../../../../vendor/assets/javascripts/i18n/moment/ar')
require('../../../../../vendor/assets/javascripts/i18n/moment/da')
require('../../../../../vendor/assets/javascripts/i18n/moment/de')
require('../../../../../vendor/assets/javascripts/i18n/moment/en')
require('../../../../../vendor/assets/javascripts/i18n/moment/es')
require('../../../../../vendor/assets/javascripts/i18n/moment/fi')
require('../../../../../vendor/assets/javascripts/i18n/moment/fr')
require('../../../../../vendor/assets/javascripts/i18n/moment/id')
require('../../../../../vendor/assets/javascripts/i18n/moment/it')
require('../../../../../vendor/assets/javascripts/i18n/moment/ja')
require('../../../../../vendor/assets/javascripts/i18n/moment/ko')
require('../../../../../vendor/assets/javascripts/i18n/moment/lv')
require('../../../../../vendor/assets/javascripts/i18n/moment/ms')
require('../../../../../vendor/assets/javascripts/i18n/moment/nl')
require('../../../../../vendor/assets/javascripts/i18n/moment/no')
require('../../../../../vendor/assets/javascripts/i18n/moment/pt-br')
require('../../../../../vendor/assets/javascripts/i18n/moment/ru')
require('../../../../../vendor/assets/javascripts/i18n/moment/sv')
require('../../../../../vendor/assets/javascripts/i18n/moment/th')
require('../../../../../vendor/assets/javascripts/i18n/moment/tr')
require('../../../../../vendor/assets/javascripts/i18n/moment/tw')
require('../../../../../vendor/assets/javascripts/i18n/moment/zh-cn')
require('../../../../../vendor/assets/javascripts/i18n/moment/zh-tw')
